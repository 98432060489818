// body {
//   background-color: #132962;
//   color: #fff;
//   max-width: 1973px; /* 1920 × 1080 */
//   margin: 0 auto;
//   padding: 0px; /* this is the padding around the map */
//   // font-family: Montserrat;
//   // font-style: normal;
// }

.mbari_container {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  max-width: 1920px;
  line-height: 0;
  overflow: hidden;
}

.zoomable_image {
  width: 100%;
}
.zoomable_image > img {
  width: 100%;
}

.mbari_container > img {
  max-width: 100%;
}

.marker1 {
  position: absolute;
  width: calc(65 / 1920 * 100%);
  left: calc((1478 - 30) / 1920 * 100%);
  top: calc((211 + 5) / 1080 * 100%);
}

.marker1.canclick:hover {
  -webkit-filter: drop-shadow(0 0 0.25vw #000000cc)
    drop-shadow(0 0 0.5vw #ffffff);
  filter: opacity(1) drop-shadow(0 0 0.25vw #000000cc)
    drop-shadow(0 0 0.5vw #ffffff);
}

.sign_post {
  width: 100%;
}
.sign_post > img {
  width: 100%;
}
.sign_post.visited > img {
  filter: brightness(0.8);
}
.marker_icon {
  position: absolute;
  width: 100%;
  bottom: 43%;
  left: 28%;
}
.marker_icon > img {
  width: 40%;
}

.corner_branding {
  position: absolute;
  // border: 1px solid red;
}
.corner_swirl {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0px;
  top: 0px;
  background-size: calc(660 / 1920 * 100%) calc(439 / 1080 * 100%);
  background-position: top right;
  background-repeat: no-repeat;
  background-blend-mode: darken;
  background-image: url("img/corner_swirl.png");
}
.corner_swirl > img {
  width: 100%;
}
.corner_logo {
  position: absolute;
  width: calc(159 / 1920 * 100%);
  height: calc(61 / 1080 * 100%);
  left: calc(1661 / 1920 * 100%);
  top: calc(101 / 1080 * 100%);
}
.corner_logo > img {
  position: absolute;
  width: 100%;
}
.nothing {
  width: 660px;
  height: 439px;
  left: 1921px;
  top: 0px;

  background: radial-gradient(
    95.68% 61.05% at 16.31% 54.23%,
    #000000 0%,
    rgba(196, 196, 196, 0) 100%
  );
  background-blend-mode: soft-light;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.map_banner_bg {
  position: absolute;
  width: 100%;
  top: calc(662 / 1080 * 100%);
  left: 0px;
}
.map_banner_bg > img {
  width: 100%;
}

.map_banner_photo {
  position: absolute;
  width: calc(260 / 1920 * 100%);
  top: calc(700 / 1080 * 100%);
  left: calc(1616 / 1920 * 100%);
}
.map_banner_photo > img {
  width: 100%;
}
.map_banner_photo_text {
  position: absolute;
  width: calc(200 / 1920 * 100%);
  left: calc(1640 / 1920 * 100%);
  top: calc(1000 / 1080 * 100%);
}
.map_banner_photo_text > img {
  width: 100%;
}

.map_banner_text {
  position: absolute;
  width: 70%;
  height: calc(120 / 1080 * 100%);
  left: calc(91 / 1920 * 100%);
  bottom: calc(37 / 1080 * 100%);

  word-break: break-word;
  font-size: 1.25vw;
  // font-family: Montserrat;
  font-weight: 500;
  // font-style: normal;
  line-height: 140%;
  display: flex;
}
.press_space {
  position: absolute;
  text-transform: uppercase;
  width: calc(450 / 1920 * 100%);
  height: calc(14 / 1080 * 100%);
  right: calc(360 / 1920 * 100%);
  top: 97%;
  font-size: 1vw;
  // font-family: Montserrat;
  font-weight: bold;
  // font-style: normal;
  line-height: 140%;
}

.mbari_hover {
  background: #ffffff;
  box-shadow: 0px 0px 30px #ffffff, 0px 0px 10px #ffffff;
}
.marker_hover {
  position: relative;

  &:hover &__no-hover {
    opacity: 0;
  }

  &:hover &__hover {
    opacity: 1;
  }

  &__hover {
    position: absolute;
    top: 0;
    opacity: 0;
  }

  &__no-hover {
    opacity: 1;
  }
}

#square {
  position: absolute;
  width: calc(26.87 / 1920 * 100%);
  height: calc(26.87 / 1920 * 100%);
  left: calc((1478 - 30) / 1920 * 100%);
  top: calc((211 + 5) / 1080 * 100%);
  transform: rotate(45deg);
  background: red;
}

/*
.marker-icon {
  position: absolute;
  left: 100px;
  top: -10px;
  width: 100%;
}
*/

.mbari_container {
  width: 100%;
}
.mbari_container > img {
  width: 100%;
}
.lab_container {
  width: 100%;
}
.lab_container > img {
  width: 100%;
}
.test_container {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  max-width: 1973px;
  line-height: 0;
}
.test_container > img {
  max-width: 100%;
}

.test_question {
  display: block;
  width: 100%;
  left: 0;
}
.test_question > img {
  display: block;
  width: 100%;
}

.clipboard .test_question.board {
  display: inline-block;
  margin-top: 0%;
  margin-bottom: 0;
  margin-right: 1.125%;
  width: 24%;
}
.clipboard .test_question.board:nth-child(4) {
  margin-right: 0;
}

.card {
  position: relative;
}

.picnic > .overlay {
  position: absolute;
  top: 40%;
  left: 17%;
  width: 20%;
}
.picnic > .overlay > img {
  width: 100%;
}

.chalkboard .test_question.board {
  width: 100%;
  height: 83%;
}
.picnic .test_question.board {
  width: 100%;
  height: 83%;
}
p.card {
  font-size: 1vw;
  background-color: #00000000;
}

.chalkboard .answer_sheet {
  position: absolute;
  left: 8%;
  top: 26%;
  width: 38%;
  min-height: 1.7vw;
  display: flex;
  height: 50%;
}
.picnic .answer_sheet {
  position: absolute;
  left: 15.5%;
  top: 26%;
  width: 24%;
  min-height: 1.7vw;
  display: flex;
  height: 60%;
  transform: rotate(-1.5deg);
}
.chalkboard.board {
  position: absolute;
  left: calc(190 / 1920 * 100%);
  top: calc(280 / 1080 * 100%);
  width: calc(700 / 1920 * 100%);
  min-height: 1.7vw;
  display: flex;
}
.picnic.board {
  position: absolute;
  left: calc(190 / 1920 * 100%);
  top: calc(280 / 1080 * 100%);
  width: calc(700 / 1920 * 100%);
  min-height: 1.7vw;
  display: flex;
}

.chalkboard .answerblank {
  position: absolute;
  width: 100%;
  display: flex;
  height: 23%;
  left: 5%;
  width: 95%;
}
.picnic .answerblank {
  position: absolute;
  width: 100%;
  display: flex;
  height: 21%;
  left: 10%;
  width: 90%;
}
.clipboard .answerblank {
  position: absolute;
  left: 9%;
  top: 0;
  width: 88%;
  min-height: 1.7vw;
  display: flex;
  height: 12%;
}
.clipboard .answerblank div {
  font-size: 2vw;
  bottom: -15%;
}
.clipboard .answer_sheet {
  position: absolute;
  height: 60%;
  width: 28%;
  bottom: 10%;
  left: 12%;
  transform: rotate(-4deg);
}
.chalkboard [id^="board-"] {
  position: absolute;
  width: 100%;
}
.picnic [id^="board-"] {
  position: absolute;
  width: 100%;
}
.board {
  z-index: 10;
}

// .board {
//   //temporary testing
//   border: 1px solid red;
// }
// .answer_sheet {
//   //testing
//   border: 1px solid white;
// }

#answer-blank-TIDE {
  bottom: 64%;
  height: 18%;
}
#answer-blank-INTERTIDAL {
  bottom: 13.5%;
  height: 12.5%;
}
#answer-blank-TIDEPOOL {
  bottom: 51%;
  height: 13%;
}
#answer-blank-LOWTIDE {
  bottom: 26.8%;
  height: 12%;
}
#answer-blank-PH {
  bottom: 39.25%;
  height: 12%;
}
#answer-blank-HIGHTIDE {
  bottom: 0.25%;
  height: 13%;
}

#answer-blank-PESTICIDES {
  top: 8%;
  height: 21%;
}
#answer-blank-SLOUGH {
  top: 37%;
  height: 18%;
}
#answer-blank-PEAT {
  top: 61.5%;
  height: 13%;
}
#answer-blank-ESTUARY {
  top: 82%;
  height: 14%;
}

#answer-blank-HOV {
  top: 2%;
  height: 20%;
}
#answer-blank-ROV {
  height: 16%;
  top: 23%;
}
#answer-blank-AUV {
  top: 40%;
  height: 20%;
}
#answer-blank-SONAR {
  top: 61%;
  height: 15%;
}
#answer-blank-SAT_TRACK_TAG {
  top: 77%;
}

.clipboard .answer_sheet .card {
  color: #000;
  left: 0%;
  font-family: "Shadows Into Light", cursive;
  font-weight: bold;
}

.picnic .answer_sheet .card {
  color: #000;

  font-weight: bold;
}
.clipboard .guide_image {
  width: 100%;
  margin-bottom: 4%;
}
.ph_testing .can_drop_here {
  -webkit-filter: drop-shadow(0 0 0.8vw #fff);
  filter: drop-shadow(0 0 0.8vw #fff);
}
.clipboard .can_drop_here {
  background-color: #00000022;
}
.picnic .can_drop_here {
  background-color: #00000022;
}
.chalkboard .can_drop_here {
  background-color: #ffffff66;
}
.dragging {
  color: #112ba2;
  background-color: #ffd702;
  display: block;
  // margin-top: 4%;
  text-align: center;
  font-size: 1.4vw;
  font-weight: bold;
  font-style: normal;
  line-height: 2vw;
  text-transform: uppercase;
  margin: 0px;
  padding: 0px;
}

div.card[draggable="false"] {
  color: #fff6;
}

.picnic img.check_mark {
  margin-left: 5%;
  height: 1.5vw;
  width: 1.5vw;
  bottom: 0.2vw;
}
.clipboard img.check_mark {
  margin-left: 5%;
  height: 2vw;
  bottom: 0.5vw;
}
img.check_mark {
  position: relative;
  bottom: 15%;
  width: 2.5vw;
  height: 2.5vw;
  margin-top: 1%;
  display: none;
}
.check_mark.correct {
  display: inline;
}

.hidden {
  visibility: hidden;
}
div.hidden img {
  display: none;
}

.auv_page {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  max-width: 1920px;
  line-height: 0;
}
.auv_bg {
  position: relative;
  left: -0.2%;
  top: 0px;
  width: 100%;
  max-width: 1980px;
  line-height: 0;
}
.auv_bg > img {
  width: 100%;
}

.auv_grid {
  position: absolute;
  left: 2.5%;
  top: 5.5%;
  width: 50%;
  height: 88.75%;
  // border: 1px solid red;
}
.auv_grid > img {
  width: 100%;
}
.auv_tablet_frame {
  width: calc(720 / 1920 * 100%);
  height: calc(780 / 1080 * 100%);
  top: 0px;
  left: 0px;

  z-index: 9999;

  line-height: 2vw;
}
/* .auv_program_pad {
  width: 70%;
  height: 75%;
  border: 1px solid red;
  float: left;
  box-sizing: border-box;
}
.auv_program_pad > h2 {
  position: relative;
  top: 5%;
  left: 5%;
  font-size: 1vw;
} */

.auv_delete_pad {
  border: 1px solid white;
  background-color: #ffffffcc;
  width: 28%;
  height: 12%;
  position: absolute;
  right: 0;
  bottom: 15%;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
  box-sizing: border-box;
}
.auv_delete_pad > div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5vw;
  color: #132962;
  line-height: 2vw;
  // border: 1px solid red;
}
.auv_delete_pad.can_drop {
  border: 1px dashed white;
  background-color: #ffffff99;
}
.auv_control_pad {
  /* display: inline-block;
  position: absolute; */
  width: 28%;
  height: 75%;
  left: 72.5%;
  top: 10%;
  line-height: 2vw;
  /* border: 1px solid greenyellow; */
  float: right;
  box-sizing: border-box;
}

.auv_control_pad .new_section {
  margin-top: 2vw;
}
.auv_control_pad button {
  width: 90%;
}

.auv_control_pad .auv_control_button:not(:last-child) {
  margin-bottom: 10%;
}
.first_movement {
  margin-top: 40%;
  border: 1px solid red;
}
.auv_control_button {
  background-color: #ffffff33;
  color: #ffffff;
  font-size: 1vw;
  // font-family: Montserrat;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  line-height: 3vw;
  text-align: center;
  height: 3vw;
  border: none;
  /* box-sizing: border-box; */
}
.r90 {
  transform: rotate(90deg);
}
.r180 {
  transform: rotate(180deg);
}
.r270 {
  transform: rotate(270deg);
}
.auv_control_button:hover {
  background-color: #ffffff99;
}
.auv_program_control {
  display: inline-block;
  width: 100%;
  margin-top: 3%;
}
.auv_program_control > p {
  display: inline-block;
  width: 48.5%;
  padding: 2% 0;
  line-height: 1vw;
}
.auv_program_control > p:nth-child(2) {
  position: absolute;
  right: 0px;
  background-color: #ffd702;
  color: #132962;
}

.auv_button_icon {
  position: absolute;
  width: 12.5%;
  height: 12.5%;
  left: 7.5%;
  top: 12%;
}
.auv_button_icon > img {
  width: 100%;
}
.auv_control_button input {
  position: absolute;
  text-align: center;
  font-size: 1.5vw;
  right: 0px;
  top: 0;
  line-height: 3vw;
  width: 2vw;
  height: 3vw;
  border: none;
  padding: 0;
  background: #cccccc;
}
// .auv_distance_container {
//   pointer-events: none;
// }
// .auv_control_pad {
//   // pointer-events: none;
// }
.spinner-button {
  position: absolute;
  cursor: default;
  z-index: 2;
  background-color: #ccc;
  color: #011ea8;
  text-align: center;
  margin: 0px;
  // pointer-events: none;
  width: 2vw;
  height: 1vw;
  line-height: 10px;
  visibility: hidden;
  z-index: 10;
}
.auv_distance_container {
  position: absolute;
  height: 3vw;
  width: 2vw;
  right: 0;
  top: 0;
  background: #ffffff99;
}
.spinner-button:hover {
  background-color: #fff;
  color: #011ea8;
}
.auv_distance_container:hover .spinner-button {
  visibility: visible;
}
// .auv_distance_container:hover {
//   pointer-events: none;
// }

//https://stackoverflow.com/questions/45396280/customizing-increment-arrows-on-input-of-type-number-using-css/45396364
input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
#inc-button {
  right: 0;
  top: -0.5vw;
}

#dec-button {
  right: 0;
  bottom: -0.5vw;
}

select {
  height: 100%;
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 1vw;
  width: 25%;
  padding-left: 0px;
  margin-left: 0px;
}
select.form-control {
  position: absolute;
  width: 25%;
  height: 100%;
  right: 0px;
  top: 0px;
  font-size: 1vw;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
ul,
li {
  margin: 0;
  line-height: 2vw;
  padding: 0;
  list-style: none;
}

.auv_dragging {
  background: #ffd702;
  color: #052c94;
}
.auv_dragging input {
  background: #ffd702;
}
.dragging_object_copy ~ div {
  transform: none !important;
  // z-index: 1000;
  // display: none !important;
}

// .dragging_object_copy {
//   // background: lightblue;
// }

.dragging_object_copy ~ [data-rbd-placeholder-context-id] {
  display: none !important;
}

.auv_program_pad {
  padding-top: 0.75vw;
  padding-left: 2%;
  display: block;
  overflow: hidden;
  float: left;
  width: 70%;
  height: 75%;
  box-sizing: border-box;
  background-color: #ffffff33;
  // border: 1px solid red;
}

.auv_program_pad.left {
  width: 35%;
}
.auv_program_pad.right {
  width: 35%;
  padding-right: 2%;
  padding-left: 0;
}

.auv_program_pad > div {
  width: 96%;
  margin-bottom: 1.1vw;
  float: left;
}
.auv_program_pad.right > div {
  width: 96%;
  margin-bottom: 1.1vw;
  float: right;
}
.auv_program_cmd {
  line-height: 2vw;
}
.not_selectable {
  color: gray !important;
}

.auv_program_control button {
  width: 48%;
  line-height: 2vw;
  background-color: #ffffff33;
  color: white;
  text-transform: uppercase;
  // font-family: Montserrat;
  font-weight: bold;
  font-size: 1.25vw;
}
.auv_program_control button:hover {
  border: 2px solid white;
}
.auv_program_control button:nth-child(1) {
  float: left;
}
.auv_program_control button:nth-child(2) {
  background: #ffd702;
  color: #052c94;
  float: right;
}
// .auv_distance_selector {
//   position: absolute;
//   top: 0;
//   right: 0;
//   width: 100%;
//   height: 100%;
//   line-height: 3vw;
//   // background-color: #ffffff33;
//   overflow: hidden;
// }

.nas-screen {
  position: absolute;
  // font-family: Montserrat;
  // font-style: normal;
  font-weight: 500;
  width: calc(762 / 1920 * 100%);
  height: calc(823 / 1080 * 100%);
  right: calc(71 / 1920 * 100%);
  top: calc(124 / 1080 * 100%);
  /* background: linear-gradient(
      0deg,
      rgba(30, 46, 86, 0.6),
      rgba(30, 46, 86, 0.6)
    ),
    linear-gradient(180deg, #0042e5 -27.55%, rgba(0, 11, 118, 0) 67.92%);
  background-blend-mode: soft-light, normal; */
  text-align: center;
  background-image: url("img/tablet_background.png");
  background-size: 100% 100%;
}
.nas-screen .padding {
  position: absolute;
  left: 3%;
  top: 3%;
  width: 94%;
  height: 94%;
}
.nas-logo {
  margin: 30% auto;
  width: 35%;
}
.nas-logo > img {
  width: 100%;
}
.nas-app-button {
  float: left;
  width: 17.5%;

  margin-left: 5%;
  margin-top: 9%;

  text-align: center;
}
.nas-app-button:nth-child(1) {
  margin-left: 7%;
}
.nas-app-button.nclickable > img {
  -webkit-filter: drop-shadow(0 0 0.8vw #fff);
  filter: drop-shadow(0 0 0.8vw #fff);
}
.nas-app-button.nclickable:hover > img {
  -webkit-filter: drop-shadow(0 0 1.2vw #fff);
  filter: drop-shadow(0 0 1.2vw #fff);
}
.nas-app-button h4 {
  font-size: 1.1vw;
}
.nas-app-button > img {
  width: 100%;
  height: 100%;
}
.nas-app-buttons {
  position: absolute;
  width: 100%;
  height: 36%;
  line-height: 36%;
  bottom: 0;
  background: rgba(4, 8, 52, 0.5);
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */
}
.as-bg {
  position: absolute;
  width: calc(762 / 1920 * 100%);
  height: calc(823 / 1080 * 100%);
  right: calc(71 / 1920 * 100%);
  top: calc(124 / 1080 * 100%);

  background: linear-gradient(
      0deg,
      rgba(30, 46, 86, 0.6),
      rgba(30, 46, 86, 0.6)
    ),
    linear-gradient(180deg, #0042e5 -27.55%, rgba(0, 11, 118, 0) 67.92%);
  background-blend-mode: soft-light, normal;
}
.as-bg > img {
  width: 100%;
  /* visibility: hidden; */
}
.as-bg > h1 {
  position: absolute;
  top: 10%;
}
.as-logo {
  position: absolute;
  top: calc(354 / 1080 * 100%);
  right: calc(319 / 1920 * 100%);
  width: calc(263 / 1920 * 100%);
  height: calc(101 / 1080 * 100%);
}
.as-logo > img {
  width: 100%;
}

.tablet-screen {
  position: absolute;
  width: calc(766 / 1920 * 100%);
  height: calc(826 / 1080 * 100%);
  right: calc(67 / 1920 * 100%);
  top: calc(123 / 1080 * 100%);
}

.large_tablet_frame {
  position: absolute;
  width: calc(925 / 1920 * 100%);
  height: calc(1080 / 1080 * 100%);
  left: calc(993 / 1920 * 100%);
  top: 0;
}

.large_tablet_frame > img {
  max-width: 100%;
}
.tablet_background {
  position: absolute;
  width: calc(760 / 1920 * 100%);
  left: calc(1087 / 1920 * 100%);
  top: calc(123 / 1080 * 100%);
}

.tablet_background > img {
  max-width: 100%;
}

.test_tablet_container {
  position: absolute;
  width: 37.5%;
  height: 72%;
  right: 4.8%;
  top: 13%;
}

.test_tablet_container > img {
  width: 100%;
}

.test_tablet_container h1 {
  display: block;
  text-transform: uppercase;
  font-size: 1.5vw;

  font-weight: bold;
  font-style: normal;
  line-height: 140%;
}
.test_tablet_container h2 {
  display: block;
  font-size: 1.25vw;

  font-weight: bold;
  font-style: normal;
  line-height: 140%;
}

.test_tablet_container .keep_trying p {
  font-size: 1.1vw;
}

.test_tablet_container h1 img {
  display: inline;
  width: 5%;
  padding-left: 2%;
}
.test_tablet_container.complete {
  display: block;
  text-align: center;
}
.test_tablet_container.complete > img {
  width: calc(99 / 720 * 100%);
}
.test_tablet_container p {
  display: block;
  font-size: 0.8vw;
  font-style: normal;
  line-height: 1.5vw;
}

.image_pad {
  margin-top: 1vw;
}
.image_card {
  position: absolute;
  text-align: left;
  width: 95%;
  height: 95%;
  left: 2.5%;
  top: 2.5%;
}
.image_card img {
  width: 100%;
  margin-left: 0;
}
.image_card h1 {
  width: 100%;
  margin-left: 0;
  font-size: 1.75vw;
  // font-style: normal;
  font-weight: bold;
  line-height: 1.75vw;
  margin-block-start: 0.5vw;
  margin-block-end: 0vw;
}
.image_card p {
  width: 100%;
  line-height: 1.3vw;
  font-size: 1vw;
  font-weight: normal;
  margin-block-start: 0.5vw;
  margin-block-end: 0vw;
}

// .zoomable_image {
//   border: 1px solid white;
// }

.info_page,
.test_message {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 64%;
  padding: 20% 0;
  text-align: center;
}

.info_page p {
  margin-left: 12.5%;
  width: 75%;
}
.info_page h4 {
  font-size: 0.9vw;
  font-weight: normal;
}

.test_message > img {
  width: 12.5%;
  position: relative;
}
.info_page > img {
  width: 40%;
  position: relative;
}

.info_page > h1,
.test_message > h1 {
  // font-family: Montserrat;
  font-size: 1.75vw;
  // font-style: normal;
  font-weight: bold;
  line-height: 140%;
  padding-top: 20px;
}
.info_page > p,
.test_message > p {
  // font-family: Montserrat;
  font-size: 1vw;
  // font-style: normal;
  font-weight: 500;
  line-height: 120%;
}
.info_page > button {
  margin-top: 4vw;
}

.info_table {
  position: absolute;
  width: 95%;
  height: 95%;
  left: 2.5%;
  top: 2.5%;
}
.info_table h2 {
  font-size: 1vw;
  font-weight: normal;
  text-align: center;
  margin-top: 2vw;
}
.info_table h1 {
  display: block;
  text-transform: uppercase;
  font-size: 1.5vw;

  font-weight: bold;
  font-style: normal;
  line-height: 140%;
}

.info_table h1.no_cap {
  text-transform: none !important;
}
.info_table > span {
  background-color: #ffffff66;
  display: inline-block;
  padding: 1vw;
  line-height: 2vw;
  width: 95%;
}
.info_table > h1 > img {
  display: inline;
  transform: scale(0.5) translateX(1vw) translateY(4vw);
}

// .info_table button {
//   position: absolute;
//   bottom: 2vw;
//   left: 42.5%;
//   height: 2vw;
// }

.chalkboard .test_tablet_container .card {
  display: block;
  margin-top: 4%;
}
.test_question.board {
  margin-bottom: 4%;
}
// .picnic .test_tablet_container .card {
//   display: block;
//   margin-top: 4%;
// }
.card_holder {
  display: block;
  margin-top: 4%;
}
.test_tablet_container .card {
  text-align: center;
  font-size: 0.8vw;
  font-weight: bold;
  font-style: normal;
  line-height: 2vw;
  text-transform: uppercase;
  background-color: #fff3;
}
// .test_tablet_container .clipboard .board {
//   height: 70%;
//   border: 1px solid red;
// }

.card_holder {
  height: 2vw;
}

// .clipboard .test_tablet_container .card {
//   display: block;
//   margin-top: 4%;
// }
// .clipboard .card_holder {
//   height: 2vw;
// }
// .clipboard .card_holder .card {
//   width: 100%;
// }
// .picnic .card_holder {
//   height: 2vw;
// }
// .picnic .card_holder .card {
//   width: 100%;
// }
.card_holder .card {
  width: 100%;
}
.card_holder {
  width: 100%;
}
.chalkboard .card,
.picnic .card {
  display: block;
  margin-top: 0%;
  margin-bottom: 0%;
  font-family: "Comic Sans MS", cursive, sans-serif;
  font-size: 1vw;
  font-weight: bold;
  font-style: normal;
  line-height: 200%;
}

.answerblank .card {
  height: 2vw;
}
.answerblank .dragging {
  height: 2vw;
}
.btn-push {
  display: inline;
  position: relative;
  padding: 5px 30px;
  line-height: 60px;
  top: 0px;
  // font-family: "Montserrat";
  font-size: 1vw;
  font-weight: bold;
  text-decoration: None;
  background: #ffd702;
  color: #052c94;
  transition: All 250ms ease;
  text-transform: uppercase;
}

.video_tablet_container {
  position: absolute;
  width: calc(37.5%);
  height: calc(66.67%);
  right: calc(4.8%);
  top: calc(15.2%);

  // font-family: Montserrat;
}
.video_tablet_container h4 {
  font-size: 1vw;
  font-weight: normal;
}
.video_tablet_container h1 {
  display: block;
  text-transform: uppercase;
  font-size: 1.5vw;

  font-weight: bold;
  font-style: normal;
  line-height: 140%;
}
.video_tablet_container > h1 > img {
  display: inline;
  width: 5%;
  padding-left: 2%;
}

.video_tablet_container p {
  display: block;
  font-size: 1.275vw;
  font-style: normal;
  line-height: 150%;
}

.video_tablet_container button {
  margin: 0 auto;
}
.video_screen {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 20.9vw;
}
.video_screen div,
.video_screen iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: calc(20.9vw);
}
iframe html {
  position: absolute;
  top: 0;
  left: 0;
}
.video_screen img {
  width: 100%;
}

.play_button {
  width: calc(91 / 1920 * 100%);
  height: calc(184 / 1080 * 100%);
  position: absolute;

  left: calc(1424 / 1920 * 100%);
  top: calc(432 / 1080 * 100%);

  background: rgba(17, 43, 162, 0.1);
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 31.5px;
}
.play_icon {
  position: absolute;
  width: calc(31.67 / 1920 * 100%);
  height: calc(25 / 1080 * 100%);
  left: calc(1456.33 / 1920 * 100%);
  top: calc(507.33 / 1080 * 100%);
}
.play_icon img {
  width: 100%;
}

.btn {
  display: block;
  position: relative;
  left: 38%;
  width: 25%;
  transition: All 250ms ease;
}
.btn img {
  width: 100%;
}
.lab_container {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  max-width: 1920px;
  line-height: 0;
}
.lab_container > img {
  max-width: 100%;
}
// .tablet_mode .lab_door {
//   display: none;
// }
// .lab_door {
//   position: absolute;
//   width: calc(260.5 / 1920 * 100%);
//   /* height: calc(492.5 / 1080 * 100%); */
// }

// .clickable_door:hover {
//   -webkit-filter: drop-shadow(0 0 0vw #fff);
//   filter: drop-shadow(0 0 0vw #fff);
// }
// .lab_mode .lab_door {
//   left: calc(960 / 1920 * 100%);
//   top: calc(324 / 1080 * 100%);
//   line-height: 0;
// }
// .lab_door > img {
//   max-width: 100%;
//   opacity: 0;
// }
// .lab_door .showglow {
//   opacity: 1;
// }
// .locked_area {
//   position: absolute;
//   width: calc(260.5 / 1920 * 100%);
// }
// .tablet_mode .locked_area {
//   left: calc(368 / 1920 * 100%);
//   top: calc(324 / 1080 * 100%);
// }

// .lab_mode .locked_area {
//   left: calc(960 / 1920 * 100%);
//   top: calc(324 / 1080 * 100%);
//   line-height: 0;
// }
// .locked_area > img {
//   max-width: 100%;
// }

// .tablet_mode .locked_area {
//   left: calc(368 / 1920 * 100%);
//   top: calc(324 / 1080 * 100%);
// }

.calling_img_crop {
  overflow: hidden;
  border-radius: 50%;
  position: absolute;
  width: 37.7%;
  left: 31.15%;
  top: 22.5%;
}
.calling_img_crop > img {
  width: 100%;
}

.as_crop_circles {
  position: absolute;
  left: 20%;
  width: 60%;
  top: 12%;
}

.as_crop_circles > img {
  width: 100%;
}

.as_crop_circles.ringing {
  filter: opacity(1);
  animation-timing-function: ease-in-out;
  animation-name: circlering;
  animation-duration: 1s;
  animation-iteration-count: 3;
}

@keyframes circlering {
  0% {
    filter: opacity(1);
  }
  33% {
    filter: opacity(1);
  }
  50% {
    filter: opacity(0);
  }
  66% {
    filter: opacity(1);
  }
  100% {
    filter: opacity(1);
  }
}
.call_scientist {
  position: absolute;
  width: calc(440 / 1920 * 100%);
  /* height: calc(70 / 1080 * 100%); */
  left: calc(1250 / 1920 * 100%);
  top: calc(186 / 1080 * 100%);
}

.call_scientist > img {
  max-width: 100%;
}
.call_in_progress {
  position: absolute;
  width: calc(726 / 1920 * 100%);
  /* height: calc(594 / 1080 * 100%); */
  left: calc(1107 / 1920 * 100%);
  top: calc(144 / 1080 * 100%);
}

.call_in_progress > img {
  max-width: 100%;
}

.newringing {
  -webkit-animation-name: ring; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 4s; /* Safari 4.0 - 8.0 */
  animation-name: ring;
  animation-duration: 2s;
  animation-iteration-count: 3;
}

// .app_button.ringing {
//   // animation: blinkshadow infinite;
//   // animation-duration: 4s;
//   box-shadow: 1vw 1vw 1vw rgba(255, 255, 255, 1);
// }
.app_button.ringing {
  filter: drop-shadow(0 0 1vw #ffffff00);
  animation-timing-function: ease-in-out;
  animation: buttonring 2s infinite;
}

@keyframes buttonring {
  0% {
    filter: drop-shadow(0 0 1vw #ffffff00);
  }
  50% {
    filter: drop-shadow(0 0 1vw white);
  }
  100% {
    filter: drop-shadow(0 0 1vw #ffffff00);
  }
}

// .app_button.ringing:hover::after {
//   opacity: 1;
// }
/* Standard syntax */
@keyframes blinkshadow {
  0% {
    -webkit-filter: drop-shadow(0 0 1vw black);
    filter: drop-shadow(0 0 1vw black);
    transition: all 0.3s ease-in-out;
  }

  50% {
    -webkit-filter: drop-shadow(0 0 1vw white);
    filter: drop-shadow(0 0 1vw white);
    transition: all 0.3s ease-in-out;
  }
  100% {
    -webkit-filter: drop-shadow(0 0 1vw black);
    filter: drop-shadow(0 0 1vw black);
    transition: all 0.3s ease-in-out;
  }
}

/* Standard syntax */
@keyframes ring {
  0% {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease-in-out;
  }

  50% {
    z-index: -1;
    opacity: 0;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    transition: opacity 0.3s ease-in-out;
  }
  100% {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease-in-out;
  }
}

.as_text_box {
  position: absolute;
  width: 100%;
  top: 65%;

  text-align: center;
}
.as_text_box h1 {
  font-size: 2vw;
  line-height: 2vw;
}
.as_text_box h2 {
  font-size: 1.5vw;
}
.as_text_box h1 h2 {
  color: #fff;
}
.incall_text_bg {
  position: absolute;
  width: calc(726 / 1920 * 100%);
  /* height: calc(846 / 1080 * 100%); */
  left: calc(1107 / 1920 * 100%);
  top: calc(758 / 1080 * 100%);
}

.incall_text_bg > img {
  max-width: 100%;
}
.incall_text {
  position: absolute;
  width: calc(686 / 1920 * 100%);
  height: 15%;

  top: 70%;
  right: calc(107 / 1920 * 100%);
  word-break: break-word;
  font-size: 1.1vw;
  // font-family: Montserrat;
  font-weight: 500;
  // font-style: normal;
  line-height: 1.5vw;
  align-items: center;
  text-align: left;
  display: flex;
}

.incall_text > p {
  max-width: 100%;
}
.incall_overlay {
  position: absolute;
  width: calc(319 / 1920 * 100%);
  height: calc(90.19 / 1080 * 100%);
  left: calc(1303 / 1920 * 100%);
  top: calc(614 / 1080 * 100%);

  word-break: break-word;
  font-size: 1vw;
  // font-family: Montserrat;
  font-weight: 500;
  // font-style: normal;

  align-items: center;
  text-align: center;
  background: #000;
}
.incall_overlay > h1 {
  width: 100%;
  font-weight: bold;
  font-size: 1.6vw;
  line-height: 110%;
}

.incall_overlay > h2 {
  width: 100%;
  font-weight: 500;
  font-size: 0.9vw;
  line-height: 110%;
}

.incall_navigation {
  position: absolute;
  width: 100%;
  line-height: 1vw;
  bottom: 0;
  left: 0;
  font-size: 1.5vw;
}
.incall_navigation > svg:nth-child(1) {
  float: left;
}
.incall_navigation > svg:nth-child(2) {
  float: right;
}
.incall_navigation .noclick {
  color: #ffffff66;
}
.blank_screen {
  position: absolute;
  width: calc(91.5 / 1920 * 100%);
  left: calc(144.5 / 1920 * 100%);
  top: calc(567.5 / 1080 * 100%);
}

.lab_mode .blank_screen {
  left: calc(736.5 / 1920 * 100%);
  top: calc(567.5 / 1080 * 100%);
}

.blank_screen > img {
  max-width: 100%;
}
.glowing_board {
  position: absolute;
  width: 12%;
  height: 16.2%;
  filter: blur(0.1vw);
  border-radius: 2.5%;
  border: 0.4vw solid white;
}

.lab_mode .glowing_board {
  left: 66.7%;
  top: 36.1%;
}
.glowing_board > img {
  max-width: 100%;
}

.call_complete .glowing_board {
  position: absolute;
  left: calc(679 / 1920 * 100%);
  top: calc(382 / 1080 * 100%);
}
.desk_tablet_screen {
  position: absolute;
  width: calc(90 / 1920 * 100%);
  /* height: calc(139 / 1080 * 100%); */
  left: calc(144.5 / 1920 * 100%);

  /* left: calc(738.5 / 1920 * 100%); */
  top: calc(566 / 1080 * 100%);
  line-height: 0;
  /* visibility: hidden; */
}
.lab_mode .desk_tablet_screen {
  left: calc(738.5 / 1920 * 100%);
}
.desk_tablet_screen.off {
  opacity: 0.8;
}
.desk_tablet_screen > img {
  max-width: 100%;
}
// .desk_tablet {
//   position: absolute;
//   width: calc(124 / 1920 * 100%);
//   /* height: calc(139 / 1080 * 100%); */
//   left: calc(144.5 / 1920 * 100%);
//   top: calc(546 / 1080 * 100%);
//   line-height: 0;
//   /* visibility: hidden; */
// }
// .lab_mode .desk_tablet {
//   left: calc(720.5 / 1920 * 100%);
// }
// .desk_tablet > img {
//   max-width: 100%;
// }
// .desk_tablet > img.showglow {
//   visibility: visible;
// }
// .desk_tablet > img.noglow {
//   visibility: hidden;
// }

.skewed {
  position: absolute;
  width: calc(91.5 / 1920 * 100%);
  left: calc(144.5 / 1920 * 100%);
  top: calc(567.5 / 1080 * 100%);

  transform: scale(0.5, 0.5);
}
.skewed > img {
  transform: skewX(10deg);
  max-width: 100%;
}

/* Firefox old*/
@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* IE */
@-ms-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* Opera and prob css3 final iteration */
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.blinking {
  -moz-animation: blink normal 2s infinite ease-in-out; /* Firefox */
  -webkit-animation: blink normal 2s infinite ease-in-out; /* Webkit */
  -ms-animation: blink normal 2s infinite ease-in-out; /* IE */
  animation: blink normal 2s infinite ease-in-out; /* Opera and prob css3 final iteration */
}

.as_text {
  position: absolute;
  width: 95%;
  margin-left: 2.5%;
  height: 22%;
  top: 76%;
  background: #0000007f;
  text-align: left;

  line-height: 1.5vw;
  /* calc(171 / 1080 * 100%); */
}
.as_text p {
  margin: 1.5%;
  font-size: 1.1vw;
  white-space: pre-line;
}

.as_video_overlay_text {
  position: absolute;
  right: calc(120 / 1980 * 100%);
  top: calc(618 / 1080 * 100%);
  width: 95%;

  text-align: center;
}
.as_video_overlay_text h1 {
  margin-top: 0px;
  font-size: 2vw;
  line-height: 2vw;
}
.as_video_overlay_text h2 {
  font-size: 1.4vw;
}
.as_video {
  position: absolute;
  width: 95%;
  margin-top: 2.5%;
  margin-left: 2.5%;

  text-align: center;
}
.as_video > img {
  width: 100%;
}

// .quiz_game {
//   // border: 1px solid red;
// }
.app_margin {
  position: absolute;
  width: 94%;
  height: 94%;
  // border: 1px solid white;
  left: 3%;
  top: 3%;
  box-sizing: border-box;
}
.button_holder {
  position: absolute;
  left: 0;
  bottom: 0;
  text-align: center;
  width: 100%;
  height: 2vw;
  line-height: 2vw;
  // border: 1px solid red;
}
.app_button {
  margin: 0 auto;
  padding: 0.3vw 1vw;
  background-color: #ffd702;
  color: #052c94;
  text-transform: uppercase;
  font-size: 1.2vw;
  line-height: 1.2vw;
}
.app_button:disabled {
  background-color: #ffd902a8;
}
.app_button:disabled:hover {
  color: #052c94;
}

.app_button:hover {
  color: white;
}

.app_button_blue {
  padding: 0.5% 5% 0.8% 5%;
  color: white;
  background-color: #052c94;
  font-weight: bold;
  line-height: 1.3vw;
  text-transform: uppercase;
}
.app_button_blue:hover {
  color: orange;
}
.n_reflect * {
  // font-family: Montserrat;
  // font-style: normal;
  font-weight: 500;
  font-size: 1vw;
}

.n_navbar {
  width: 100%;
  height: 5.3%;
  background: #ffffff33;
  text-transform: uppercase;
  font-size: 1vw;
  line-height: 2.4vw;
  padding-left: 1%;
  padding-right: 1%;
  box-sizing: border-box;
}
.n_navbar .back {
  float: left;
}
.n_navbar .back:hover {
  text-decoration: underline;
}
.n_navbar .breadcrumb {
  display: block;
  float: right;
}
.n_navbar .breadcrumb .root {
  color: #fff;
  float: left;
}
.n_navbar .breadcrumb .current {
  float: right;
}
.root:hover {
  text-decoration: underline;
}
// .n_folder {
//   float: left;
//   width: 45%;
// }

.n_info {
  width: 100%;
  height: 93%;
  background: #ffffff33;
  text-align: center;
}

.n_info h1 {
  font-size: 1.5vw;
  line-height: 1.75vw;
}
.n_info h2 {
  font-size: 1.25vw;
  line-height: 1.3vw;
}
.n_info p {
  text-align: left;
  font-size: 1.25vw;
  line-height: 1.3vw;
}

.n_info p {
  padding: 0 3%;
}

.n_reflect {
  margin: 3% 0% 0 0%;
  width: 100%;
  height: 5.3%;
  background: #ffffff33;

  padding-left: 1%;
  padding-right: 1%;
  padding-top: 1vw;
  box-sizing: border-box;
}
.n_file_info {
  display: inline-block;
  width: 100%;
  background: #fff3;
  line-height: 1.75vw;
  font-size: 0.8vw;
}
.n_file_info div:nth-child(1) {
  margin-left: 2%;
  float: left;
}
.n_file_info div:nth-child(2) {
  margin-right: 2%;
  float: right;
}
.n_reflect h1 {
  font-weight: bold;
  font-size: 1vw;
  line-height: 2.4vw;
}
.n_answer {
  width: 93%;
  margin-top: 0px;
  // background: #fff3;
  line-height: 1.2vw;
  margin-top: 2%;
  font-size: 0.9vw;
}
.n_answer:focus {
  background-color: #ffffff33;
}
.n_saved {
  color: #fff;
  text-decoration: underline;
}
.n_not_saved {
  color: #ffd702;
}
.n_answer:focus {
  outline-width: 0;
}
.n_reflect {
  height: 85%;
}
.n_questions {
  text-align: left;
  line-height: 1vw;
  width: 100%;
  font-size: 0.9vw;
  margin: 0 3% 0 3%;
}

.notes_figure,
.n_questions > p > img {
  width: 40%;
  float: right;
  margin-right: 3%;
  margin-top: 3%;
}
.notes_figure > img {
  width: 100%;
}
.notes_figure > figcaption {
  margin: 0 3% 3% 3%;
}
.n_folders {
  position: absolute;
  top: 3vw;
  left: 0;
  width: 100%;
  height: 94%;
  // border: 1px solid red;
}
.n_folder {
  float: left;
  width: 49%;
  padding: 2% 2% 0 2%;
  background: #ffffff33;
  height: 45%;
  box-sizing: border-box;
}
.n_folder:nth-child(2) {
  float: right;
}
.n_folder:nth-child(4) {
  float: right;
}
.n_folder:nth-child(n + 3) {
  margin-top: 3%;
}
.n_folder img {
  width: 100%;
}

figure {
  width: 100%;

  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.n_folder:hover {
  outline: 1px solid white;
  outline-offset: -1px;
}

.n_folders figcaption {
  text-align: left;
}
.n_folders figcaption h1 {
  font-size: 1.1vw;
  line-height: 0.5vw;
  margin: 0;
}
.n_folders figcaption h2 {
  font-size: 0.9vw;
  line-height: 0.5vw;
  font-weight: normal;
}
.n_folders figcaption h3 {
  color: #f5b00a;
  font-size: 0.8vw;
  line-height: 0.3vw;
}

.n_info figure {
  float: left;
  width: 45%;
  margin: 0;
  text-align: center;
  padding: 0 0 0 3%;
}
.n_info figure img {
  display: inline;
  margin: 0px;
  padding: 0px;
  width: 100%;
  margin: 0 auto;
}
.n_info figcaption {
  line-height: 1.5vw;
  font-size: 1vw;
}
.n_bottom_nav {
  position: absolute;
  bottom: 3%;
  left: 5%;
  width: 90%;
  margin: 0;
  height: 3%;
  line-height: 1.3vw;
  font-size: 1.25vw;
  text-align: center;
  box-sizing: border-box;
}
.n_back_arrow {
  float: left;
}
.n_forward_arrow {
  float: right;
}
.n_no_click {
  color: #999;
}

.reward_badge:nth-child(1) {
  left: 12%;
}
.reward_badge:nth-child(2) {
  right: 12%;
}

.reward_badge {
  position: absolute;
  top: 50%;
  width: 35%;
  height: 10vw;
  text-align: center;
}
.badge_crop_circles img {
  width: 13vw;
}
.reward_badge figure {
  width: 100%;
}
.claimed_reward {
  width: 75%;
  filter: drop-shadow(-0.8rem 1rem 0.5rem #ff8800);
}

.reward_badge figcaption {
  margin-top: 0%;
}
.reward_badge h1 {
  font-size: 1vw;
  line-height: 1.5vw;
}
.reward_badge h2 {
  font-size: 0.8vw;
}
.nas-screen {
  background-blend-mode: screen;
}

.badge_crop_circles {
  position: absolute;
  right: 0;
  width: 100%;
  top: -21%;
}
.badge_crop_circles > img {
  width: 100%;
}

.nl_title {
  font-size: 1.65vw;
  margin-top: 30%;
  text-transform: uppercase;
}
.nl_subtitle {
  font-size: 1vw;
  margin-top: 16%;
  text-transform: uppercase;
}
.nl_highlight {
  margin: 8% 33%;
  position: absolute;
  width: 33%;
  height: 33%;
}
.nl_medal {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.nl_medal img {
  width: 100%;
  height: 100%;
}
.nl_profile {
  position: absolute;
  width: 41%;
  top: 25%;
  left: 30%;
}
.nl_profile img {
  width: 100%;
  height: 100%;
}
.nl_level {
  position: absolute;
  left: 44%;
  bottom: 16%;
  font-size: 3vw;
  font-weight: bold;
}

.nl_crop_circles {
  position: absolute;
  width: 160%;
  left: -30%;
  top: -28%;
}
.nl_crop_circles img {
  width: 100%;
}

.blur_bg > div:nth-child(1) > img {
  -webkit-filter: blur(8px); /* Safari 6.0 - 9.0 */
  filter: blur(8px);
}
.blur_bg div.zoomable_image > div:nth-child(1) > img {
  -webkit-filter: blur(8px); /* Safari 6.0 - 9.0 */
  filter: blur(8px);
}
.blur > img:nth-child(1) {
  -webkit-filter: blur(8px); /* Safari 6.0 - 9.0 */
  filter: blur(8px);
}

.trophy_tablet {
  position: absolute;
  width: 100%;
  left: 0%;
  top: 0%;
}
.trophy_frame {
  position: relative;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
}
.trophy_frame img {
  width: 100%;
}
.trophy_bg {
  position: absolute;
  width: 78%;
  left: 9%;
  line-height: 0;

  top: 23%;
}
.trophy_bg img {
  width: 100%;
  z-index: 2;
}

.trophy_collection {
  position: absolute;
  left: 35.05%;
  width: 35%;
  height: 3%;
  top: 18.1%;
  z-index: 2;
}
.trophy_label {
  position: absolute;
  top: 20.1%;
  left: 29.5%;
  font-size: 1vw;
  z-index: 2;
}
.trophy_room_label {
  position: absolute;
  top: 20.1%;
  left: 74.5%;
  font-size: 1.7vw;
  z-index: 2;
}
.trophy_badge {
  display: inline-block;
  margin: 0px;
  width: 7%;
}

.trophy_badge:not(:first-child) {
  margin-left: 6.5%;
}
.trophy_badge img {
  width: 100%;
}
.trophy_profile_pic {
  position: absolute;
  left: 7.35%;
  top: 9.7%;
  width: 5.5%;
  z-index: 2;
}
.trophy_profile_pic img {
  width: 100%;
}
.trophy_screen {
  position: absolute;
  left: 9.2%;
  top: 24.2%;
  width: 77.5%;
  height: 58%;
  z-index: 2;
}
.trophy_tab_container {
  position: absolute;
  width: 97%;
  height: 10%;
  left: 1.5%;
  top: 3%;
}
.trophy_tabs {
  display: inline-block;
  background: #fff3;
  height: 100%;
  width: 19.2%;
  box-sizing: border-box;
  padding-top: 2%;
  font-weight: bold;
  font-size: 1.2vw;
  text-transform: uppercase;
  text-align: center;
}
.trophy_tabs.selected {
  background-color: #ffd702;
  color: #052c94;
}
.trophy_tabs:not(:first-child) {
  margin-left: 1%;
}

.trophy_cell {
  margin-top: 6.25%;
  height: 82.5%;
  background-color: #fff3;
}
.trophy_cell.left {
  float: left;
  margin-left: 1.5%;
  width: 43%;
}
.trophy_cell.right {
  float: right;
  margin-right: 1.5%;
  width: 53%;
}

.info_cell {
  box-sizing: border-box;
  padding: 0% 1%;
  margin: 2%;
}
.info_cell.selected {
  background-color: #ffd702;
  color: #052c94;
}
.info_cell.thirty {
  height: 30%;
}
.info_cell.twentyfive {
  height: 25%;
}
.info_cell.seventy {
  height: 70%;
}
.info_cell.onehundred {
  height: 100%;
}
.info_cell h1 {
  font-size: 1.3vw;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 1%;
  padding-bottom: 0%;
  margin-bottom: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}

.info_cell p {
  font-size: 1vw;
  font-weight: normal;
  line-height: 1.25vw;
  margin-block-start: 0;
  margin-block-end: 0;
}
.info_cell img {
  width: 100%;
  margin-bottom: 2%;
}
.info_cell button {
  float: right;
  position: absolute;
  bottom: 6%;
  right: 4%;
}
.trophy_level_bg {
  position: absolute;
  left: 8.1%;
  top: 19.8%;
  width: 4%;
  z-index: 2;
}
.trophy_level_bg img {
  width: 100%;
}

.incall {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}
.incall > h1 {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  font-size: 1.2vw;
  position: absolute;
  left: 40%;
  top: 7%;
  color: #ffffff99;
}
.ph_meter {
  position: absolute;
  top: 34%;
  left: 18%;
  width: 17%;
  height: 42%;
}
.ph_meter > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.ph_meter.home {
  position: absolute;
  top: 34%;
  left: 18%;
  width: 17%;
  height: 42%;
}
.ph_meter.measuring {
  transform: scale(0.8);
  z-index: 0;
}
.ph_meter.measuring.left {
  left: 5%;
  top: 20%;
}
.ph_meter.measuring.right {
  left: 39%;
  top: 20%;
}
.ph_meter img.show_hover:hover {
  -webkit-filter: drop-shadow(3px 3px 2px white);
  filter: drop-shadow(3px 3px 2px white);
}
.clickable:hover {
  border: 0.2vw solid white;
}
.inset_clickable:hover {
  outline: 1px solid white;
  outline-offset: -1px;
}

.kayak_marker img:nth-child(2) {
  pointer-events: none;
}
.kayak_marker:hover img.show_hover {
  -webkit-filter: drop-shadow(3px 3px 2px black);
  filter: drop-shadow(3px 3px 2px black);
}

img.show_hover .markertag {
  position: absolute;
  width: 18vw;
  height: 2vw;
}
.dotted_line {
  border-top: 0.2vw dashed #ffffff;
  position: absolute;
  left: 2vw;
  margin-top: 0.8vw;
  width: 5vw;
  pointer-events: none;
}
.colored_square {
  position: absolute;
  width: 1.5vw;
  height: 1.5vw;
  top: 0;
  left: 0;
  transform: rotate(45deg) scale(1);
  pointer-events: none;
}
.colored_square.one {
  background-color: #ffb802;
  border: 2px solid #ffdd21;
}
.colored_square.two {
  background-color: #052c94;
  border: 2px solid #0042e5;
}
.colored_square.three {
  background-color: #8618c8;
  border: 2px solid #ad4ee6;
}
.colored_square.four {
  background-color: #db493d;
  border: 2px solid #ff4e4e;
}
.colored_square.five {
  background-color: #1a9b5d;
  border: 2px solid #45cc90;
}
.marker_number {
  position: absolute;
  font-size: 1vw;
  padding: 0.2vw;
  pointer-events: none;
}
.marker_value {
  position: absolute;
  font-size: 1.1vw;
  font-weight: bold;
  top: -0.5vw;
  right: 0;
  padding: 0.5vw 1.6vw;
  line-height: 2vw;
  color: #052c94;
  background-color: #ffffff;
  pointer-events: none;
}

.markertag.chosen .marker_value {
  background-color: #ffd702;
}

div.markertag:hover div.show-hover {
  transform: scale(1.5, 1.5) rotate(45deg);
}
div.markertag:hover {
  -webkit-filter: drop-shadow(0 0 0.5vw white);
  filter: drop-shadow(0 0 0.5vw white);
}
.env_elkhorn.shift .table_tablet {
  left: 0.4%;
}
.env_elkhorn .table_tablet {
  position: absolute;
  width: 35%;
  left: 9%;
  bottom: 0;
}
.env_elkhorn.shift .table_tablet {
  left: 0.4%;
}
.env_elkhorn .crate_w_sensor {
  position: absolute;
  width: 8.5%;
  left: 44%;
  bottom: 0;
}
.env_elkhorn.shift .crate_w_sensor {
  left: 35.4%;
}
.env_elkhorn .shack_door {
  position: absolute;
  width: 6.3%;
  left: 33%;
  top: 48.5%;
}
.env_elkhorn.shift .shack_door {
  left: 24.4%;
}
.env_elkhorn.shift .table_tablet {
  left: 0.4%;
}

.env_shack .floor_tablet {
  position: absolute;
  width: 76%;
  height: 76%;
  left: 29.5%;
  top: 10.5%;
}
.env_shack.shift .floor_tablet {
  left: 9.2%;
}

.env_shack .locked_kayak {
  position: absolute;
  width: 51%;
  left: 19%;
  top: 36.5%;
}
.env_shack.shift .locked_kayak {
  left: -1%;
}

.env_shack .clipboard {
  position: absolute;
  width: 76%;
  height: 76%;
  left: -15%;
  top: 26%;
}
.env_shack.shift .clipboard {
  left: -35.2%;
}

.kc_kayak {
  position: absolute;
  z-index: 999;
  width: 40%;
  height: 40%;
  left: 7%;
  top: 61%;
  // border: 1px solid red;
}
.kc_kayak.center {
  left: 30%;
}
.icon_instructions {
  position: absolute;
  left: 2.5%;
  top: 2.5%;
  width: 95%;
  height: 95%;
  text-align: center;
}
.icon_instructions .icon_holder {
  margin: 8vw auto 2vw auto;
  width: 20%;
}
.icon_instructions .sample_icon {
  margin: 4vw auto 2vw auto;
  width: 50%;
}
.sample_icon img {
  width: 100%;
}
.icon_instructions h1 {
  text-transform: uppercase;
  font-size: 1.7vw;
  margin-bottom: 8%;
}
.icon_instructions p {
  text-align: center;
  line-height: 1.35vw;
  font-size: 1.25vw;
  margin-left: 7%;
  margin-right: 7%;
  margin-bottom: 10%;
}
.icon_instructions button {
  padding-left: 8%;
  padding-right: 8%;
}

div.show_hover img:nth-of-type(1) {
  visibility: visible;
}
div.show_hover img:nth-of-type(2) {
  visibility: hidden;
}

div.show_hover:hover img:nth-of-type(1) {
  visibility: hidden;
}
div.show_hover:hover img:nth-of-type(2) {
  visibility: visible;
}

.env_samplingtable img.show_hover:hover {
  -webkit-filter: brightness(2) !important;
  filter: brightness(2) !important;
}
img.show_hover:hover {
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
}

.env_samplingtable img.show_hover:hover {
  -webkit-filter: brightness(2) !important;
  filter: brightness(2) !important;
}

div.clipboard img.show_hover:hover {
  -webkit-filter: drop-shadow(0 0 1vw white);
  filter: drop-shadow(0 0 1vw white);
}

div.clipboard .message {
  position: absolute;
  color: black;
  top: 88%;
  left: 65%;
  transform: rotate(2.5deg);
  pointer-events: none;
  font-size: 1.5vw;
}
.kg_gallery_image {
  width: 47.5%;
  margin: 1%;
  float: left;
  margin-bottom: 2%;
}
.kg_gallery_image > img {
  max-width: 100%;
  max-height: 7vw;
}
.kg_gallery_image > figcaption {
  line-height: 1.5vw;
  margin: 2%;
}
.kg_gallery_image:hover {
  outline: 1px solid white;
  outline-offset: -1px;
}
div.row {
  width: 100%;
  float: left;
}
.row > .left {
  font-weight: bold;
  float: left;
}
.row > .right {
  float: right;
}
.wt_sample_list {
  width: 100%;
  height: 70%;
}
.wt_sample_list h4 {
  margin-top: 2vw;
  font-size: 1vw;
  font-weight: normal;
  text-align: center;
}
.wt_sample {
  position: relative;
  margin-bottom: 2%;
  background-color: #ffffff33;
  width: 100%;
  height: 4vw;
  line-height: 4vw;
}
.wt_sample_marker {
  position: absolute;

  left: 0%;
  top: 0%;
  width: 2vw;
  height: 2vw;

  border: 1px solid white;
  transform: translate(-1vw, -1vw) rotate(45deg) translate(2.25vw, 0);
}
.wt_marker_container {
  position: absolute;
  left: 2vw;
  top: 0.25vw;
  width: 3.25vw;
  height: 3.25vw;
}
.wt_marker_label {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 3.4vw;
  font-size: 1.2vw;
}
.wt_sample_text {
  margin-left: 15%;
  font-size: 1.5vw;
}

.tablet_video {
  width: 100%;
  height: 20vw;
  min-height: 100%;
}

.env_lab.shift .clickables {
  // border: 1px solid red;
  position: absolute;
  left: -30.8%;
  top: 0;
  width: 100%;
  height: 100%;
}
.env_lab .desk_tablet {
  position: absolute;
  width: 5%;
  left: 38.3%;
  top: 52.2%;
}

.env_lab .desk_tablet img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.env_lab .desk_tablet img:nth-child(2) {
  position: absolute;
  left: -1vw;
  top: -1vw;
  width: 135%;
}
.env_tidepool .table_tablet {
  position: absolute;
  width: 12.7vw;
  left: 50.3%;
  top: 25.1%;
}
.env_tidepool.shift .table_tablet {
  left: 30.2%;
}
.env_tidepool .backpack {
  position: absolute;
  width: 14.5vw;
  left: 27.2%;
  top: 46%;
}
.env_tidepool.shift .backpack {
  position: absolute;
  left: 7%;
}
.env_tidepool .clipboard {
  position: absolute;
  width: 17.5%;
  left: 44.5%;
  top: 73.5%;
}
.env_tidepool.shift .clipboard {
  left: 24.5%;
}

.admin_page {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.admin_page > img {
  width: 100%;
}

.admin_form {
  background-color: white;
  position: absolute;
  left: 10%;
  top: 10%;
  width: 36%;
  height: 74%;
  color: #132962;
  padding: 2%;
  border-radius: 2vw;
}
.ant-row {
  margin: 1%;
}
.admin_form h2 {
  margin: 0;
  margin-bottom: 2vw;
  color: #132962;
}
.admin_form i,
.admin_form input {
  margin-right: 1vw;
}
// .contentPane {
//   width: 100%;
//   border: 1px solid red;
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 40%;
//   height: 90%;
// }
.inputLabel {
  margin-bottom: 1vw;

  color: black;
  line-height: 2vw;
}

// .ant-btn {
//   margin-top: 1vw;
// }
.ant-btn {
  line-height: 1.5;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  height: 32px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color: #d9d9d9;
}

.ant-btn-primary {
  color: white;
}
.ant-form-item-control {
  line-height: 1.5vw !important;
}

.admin_form {
  font-size: 2vw;
}

.ant-input {
  height: 3vw !important;
  font-size: 1.5vw !important;
}

.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 4vw !important;
}
.ant-input-affix-wrapper .ant-input-prefix {
  left: 1vw !important;
}
.ant-input-affix-wrapper {
  font-size: 1.5vw !important;
}

.ant-input {
  font-size: 2vw;
}
.ant-input::-webkit-input-placeholder {
  font-size: 2vw;
}
.ant-btn-primary {
  background-color: #112ba2 !important;
  border-color: #112ba2 !important;
}
.ant-modal {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum", "tnum";
  position: relative;
  top: 100px;
  width: auto;
  margin: 0 auto;
  padding-bottom: 24px;
  pointer-events: none;
}
// .admin_form span {
//   font-size: 2vw;
// }

.logout_block {
  z-index: 10;
  top: 2%;
  right: 1%;
  font-size: 1.5vw;
}
.login_block {
  z-index: 10;
  top: 3%;
  left: 3%;
}
.logout_block:hover,
.login_block:hover {
  color: white;
}
.login_block,
.logout_block {
  position: absolute;
  color: #ffffffcc;
}
.login_block a,
.login_block a:visited {
  color: #ffffffcc;
}
.logout_block a,
.logout_block a:visited {
  color: #ffffffcc;
}

.quiz_intro_wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  text-align: center;
}
.quiz_intro_wrapper h1 {
  font-size: 1.6vw;
  text-transform: uppercase;
  /* border: 1px solid green; */
  line-height: 2vw;
  margin-bottom: 8%;
}

.quiz_intro_wrapper p {
  font-size: 1vw;
  margin: 0 5% 0 5%;
  line-height: 2vw;
  display: block;
  word-break: break-word;
}
.quiz_intro_wrapper h1,
.quiz_intro_wrapper p {
  text-align: center;
}
.quiz_intro_wrapper img {
  padding-top: 20%;
  width: 15%;
  height: 15%;
}
.quiz_intro_wrapper button {
  margin-top: 8%;
}

.quiz_intro_wrapper,
.quiz_wrapper {
  font-weight: bold;
  padding: 3%;
  box-sizing: border-box;
  // border: 1px solid red;
}
.quiz_wrapper button {
  float: right;
}

// .quiz_wrapper span {
//   float: left;
//   /* border: 1px solid red; */
//   white-space: nowrap;
//   font-size: 1.6vw;
// }
.quiz_wrapper span {
  text-align: left;
  white-space: normal;
  word-break: break-all;
  font-size: 0.95vw;
  text-transform: uppercase;
}

.quiz_game {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 0%;
  box-sizing: border-box;
}
.quiz_pad {
  // border: 1px solid green;
  position: absolute;
  left: 0;
  top: 15%;
  width: 100%;
  height: 53%;
}

.drop_pad {
  position: absolute;
  left: 0;
  top: 68%;
  width: 100%;
  height: 10vw;
  line-height: 10vw;
  text-align: center;
  border-style: dashed;
  border-width: 0.2vw 0;
  border-color: white;
}

.drop_pad.can_drop_here {
  background-color: #ffffff66;
}
// .drop_pad_overlay {
//   position: absolute;
//   left: 0;
//   top: 68%;
//   border-style: dashed;
//   border-width: 0.2vw 0;
//   border-color: white;
//   width: 100%;
//   height: 10vw;
//   line-height: 10vw;
//   text-align: center;
//   z-index: 11;
// }
.drop_pad > h1 {
  font-size: 1.5vw;
  line-height: 1.5vw;
  margin: 4vw auto;
  vertical-align: middle;
}
.drop_pad > .hide {
  display: none;
}

.drag_a_card .drop_pad {
  position: absolute;
  left: 0;
  top: 68% !important;
  display: block;
  text-align: center;
  z-index: 10;
  line-height: 2vw;
  height: 10vw;
}

.drop_pad > .instructions {
  // font-size: 1.5vw;
  // line-height: 1.5vw;
  // margin: 4vw auto;
  // vertical-align: middle;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.drop_pad > .instructions {
  // font-size: 1.5vw;
  // line-height: 1.5vw;
  // margin: 4vw auto;
  // vertical-align: middle;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  line-height: 10vw;
  pointer-events: none;
}
.drop_pad .game_instruction {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.word_card {
  height: 1vw;
  line-height: 1vw;
  padding: 0.6vw;
  font-size: 0.9vw;
  vertical-align: middle;
  z-index: 10;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: white;
}

.card_pad .word_card[draggable="true"]:hover {
  outline: 1px solid white;
}
.card_pad .word_card[draggable="false"] {
  filter: brightness(0.5);
}
// .card_pad .word_card[draggable="true"]:hover {
//   box-shadow: 0 0 1vw white;
//   cursor: pointer;
// }

.drag_a_card .word_card.being_dragged {
  color: #112ba2;
  background-color: #ffd702;
  z-index: 1000;
}
.drag_a_card .drop_pad .word_card {
  color: #112ba2;
  background-color: #ffd702;
  z-index: 1;
  pointer-events: none;
}

.drag_a_card .drop_pad div {
  pointer-events: none;
}
.drag_a_card .drop_pad .word_card.incorrect {
  background-color: red;
  z-index: 1;
}
@keyframes blink_box_shadow {
  0% {
    box-shadow: 0 0 1vw #ffd702;
  }
  50% {
    box-shadow: none;
  }
  100% {
    box-shadow: 0 0 15px #ffd702;
  }
}

@-webkit-keyframes blink_box_shadow {
  0% {
    box-shadow: 0 0 15px #ffd702;
  }
  50% {
    box-shadow: 0 0 0;
  }
  100% {
    box-shadow: 0 0 15px#ffd702;
  }
}

.drag_a_card .word_card.hint {
  -webkit-animation: blink_box_shadow 1s linear infinite;
  -moz-animation: blink_box_shadow 1s linear infinite;
  -ms-animation: blink_box_shadow 1s linear infinite;
  -o-animation: blink_box_shadow 1s linear infinite;
  animation: blink_box_shadow 1s linear infinite;
}
.image_pad .word_card {
  position: absolute;
  background-color: #ffffff4c;
}
.image_header {
  margin-top: 1vw;
  height: 27%;
}
.card_pad {
  position: absolute;
  top: 16.5vw;
  height: 11vw;
  display: flex-grow;
  text-align: center;
  width: 112%;
  left: -5%;
}
.drag_a_card .word_card {
  background-color: #ffffff7f;
  display: inline-block;
  margin: 2vw 0.5vw 0 0;
  // margin-top: 2vw;
}
.word_card.can_drop_here {
  background-color: #ffffffcc;
  color: #000;
}
.quiz_flag {
  position: absolute;
  right: 0;
  top: 2.5vw;
  width: 3.5vw;
  height: 4.13vw;
  // border: 1px solid red;
}
.quiz_flag img {
  // transform: rotate(15deg) scale(0.8);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
// .quiz_flag.being_dragged {
// }
.quiz_card {
  width: 7vw;
  height: 8vw;
  padding: 0.5vw;
  z-index: 10;
  position: absolute;
  text-align: center;
  background-color: #334595;
}
.quiz_card.being_dragged {
  background-color: #ffd702;
}
.quiz_card.dropped {
  top: 22vw !important;
  // transform: scale(0.75) !important;
}
.quiz_card.dropped.clear {
  left: 0 !important;
}
.quiz_card[draggable="false"] {
  filter: brightness(0.5);
}
.quiz_card[draggable="true"]:hover {
  box-shadow: 0 0 1vw white;
  cursor: pointer;
}
.quiz_flag[draggable="false"] {
  filter: brightness(0.5);
}
.quiz_flag[draggable="true"] img:hover {
  -webkit-filter: drop-shadow(8px 8px 10px #fff);
  filter: opacity(1) drop-shadow(0.3vw 0.3vw 2px #fff);
  cursor: pointer;
}
@keyframes move-then-clear {
  50% {
    top: 200px;
    left: 200px;
  }
  75% {
    top: 200px;
    left: 200px;
  }
  100% {
    top: 200px;
    left: 0px;
  }
}
.quiz_card {
  -webkit-transition-property: width height background-color font-size left top
    transform -webkit-transform color;
  -webkit-transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-in-out;
  transition-property: width height background-color font-size left top
    transform -webkit-transform color;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}
.quiz_card.c0 {
  position: absolute;
  left: 3%;
  top: 3%;
  transform: rotate(-3deg);
}
.quiz_card.c1 {
  left: 27%;
  top: 20%;
  transform: rotate(0deg);
}
.quiz_card.c2 {
  left: 51%;
  top: 40%;
  transform: rotate(5deg);
}
.quiz_card.c3 {
  left: 75%;
  transform: rotate(-2deg);
}
.quiz_card img {
  width: 100%;
  z-index: 9;
}
.quiz_card h1 {
  font-size: 1vw;
  margin-top: 1vw;
  margin-block-start: 0;
  margin-block-end: 0;
  margin: 0.8vw auto;
}

.quiz_game > h1 {
  margin: 0;
  width: 100%;
  display: table;
  // border: 1px solid red;
}
.quiz_game h2 {
  font-size: 1.2vw;
  font-weight: normal;
  line-height: 1.2vw;
  margin: 0;
  // border: 1px solid red;
}
.quiz_title {
  font-size: 1.6vw;
  text-transform: uppercase;
  line-height: 2vw;
  vertical-align: middle;
  display: table-cell;
}
.question_number {
  vertical-align: middle;
  display: table-cell;
  text-align: right;
  font-size: 2vw;
  opacity: 0.8;
}
.quiz_icon {
  vertical-align: middle;
  display: table-cell;
}
.quiz_icon img {
  width: 2vw;
  opacity: 0.3;
}
.quiz_item {
  background-color: #ffffff33;
  margin-bottom: 2.5%;
  text-align: left;
  position: relative;
  padding-top: 0.75vw;
  padding-left: 0.75vw;
  height: 2.25vw;
  // vertical-align: middle;
}

.quiz_item input[type="checkbox"] {
  visibility: hidden;
  pointer-events: none;
}
// .quiz_item input[type="checkbox"]::before {
//   visibility: hidden;
// }
.quiz_item:hover {
  // outline: 0.15vw solid red !important;
  background-color: #ffffff66;
}
// .quiz_item:hover .checkbox {
// }
.checkbox {
  float: left;
  width: 1.5vw;
  height: 1.5vw;
  padding-top: 0px;

  // padding-left: 1vw;
  line-height: 1.5vw;
  outline: 0.15vw solid white;
}
// div.checkbox img:hover {
//   border: 0.15vw solid red;
// }
.checkbox img {
  width: 100%;
  // visibility: hidden;
}
.quiz_item * {
  pointer-events: none;
}
.quiz_item span {
  padding-left: 1vw;
  line-height: 1.6vw;
}
.quiz_img_photos {
  width: 100%;
}
.quiz_img_photos > img {
  width: 48.5%;
}
.quiz_img_photos > img:nth-child(2) {
  margin-left: 3%;
}

.quiz_title {
  font-size: 1.6vw;
  text-transform: uppercase;
  /* border: 1px solid green; */
  line-height: 2vw;
  padding: 0;
  margin-top: 5%;
  width: 35%;
}
.quiz_title > img {
  display: block;
  float: right;
  width: 15%;
  opacity: 0.3;
  margin: 0%;
  padding: 0;
}

.slider {
  position: absolute;
  height: 6%;
  width: 38%;
  bottom: 10%;
  left: 30%;
  background-color: #ffffff7f;
  border-radius: 999px;
}
.slider h1 {
  position: absolute;
  top: 25%;
  right: 15%;
  font-size: 1vw;
  font-weight: bold;
  text-transform: uppercase;
}
.slider > div {
  position: absolute;
  height: 100%;
  width: 17%;
  left: 0px;
  top: 0px;
  background-color: #ffd702;
  border-radius: 999px;
}
.slide {
  transform: translate(490%, 0);
  -webkit-transform: translate(490%, 0);
}

.slide-enter {
  transform: translateX(0%);
}
.slide-enter-active {
  transform: translateX(490%);
  transition: transform 600ms;
}
.slide-exit {
  transform: translateX(490%);
}
.slide-exit-active {
  transform: translateX(0%);
}
.slider {
  position: absolute;
  height: 6%;
  width: 38%;
  bottom: 10%;
  left: 30%;
  background-color: #ffffff7f;
  border-radius: 999px;
}
.switch {
  position: absolute;
  left: 30%;
  bottom: 10%;
  width: 15vw;
  height: 3vw;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.new_slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff7f;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.new_slider::before {
  position: absolute;
  content: "";
  height: 3vw;
  width: 3vw;
  left: 0vw;
  bottom: 0vw;
  background-color: #ffd702;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background-image: url("img/arrow.svg");
  background-size: cover;
}

input:checked + .new_slider {
  background-color: #ccc;
}

input:focus + .new_slider {
  box-shadow: 0 0 1vw white;
}

input:checked + .new_slider::before {
  -webkit-transform: translateX(12vw);
  -ms-transform: translateX(12vw);
  transform: translateX(12vw);
}

/* Rounded sliders */
.new_slider.round {
  border-radius: 999px;
}

.new_slider.round::before {
  border-radius: 999px;
}

.switch h1 {
  font-size: 1vw;
  width: 15vw;
  margin-right: 0px;
  margin-left: 4vw;
  padding: 0;
  text-transform: uppercase;
}

.explorer_badge {
  position: absolute;
  width: 27%;
  top: -1%;
  left: -1%;
}
.explorer_badge * {
  font-size: 1vw;
  font-family: Montserrat;
  font-style: normal;
  color: white;
}
.explorer_badge h1,
.explorer_badge h2,
.explorer_badge h3 {
  position: absolute;
  margin-block-start: 0;
  margin-block-end: 0;
  width: 100%;
  left: 41%;
  // border: 1px solid red;
}
.explorer_badge h4 {
  position: absolute;
  width: 55%;
}
.explorer_badge h3 {
  line-height: 1vw;
}
.explorer_badge img {
  width: 100%;
}
.explorer_badge .explorer_thumbnail {
  position: absolute;
  bottom: 42.75%;
  left: 17.47%;
  width: 12%;
}
.explorer_badge > h1 {
  position: absolute;
  font-weight: bold;
  top: 42%;
}
.explorer_badge h2 {
  position: absolute;
  font-weight: 300;
  top: 50%;
}
.explorer_badge h3 {
  position: absolute;
  font-weight: normal;

  top: 63.5%;
  label {
    float: left;
    font-weight: bold;
  }
  div {
    padding-left: 2%;
    overflow: hidden;
  }
}
.explorer_level {
  font-size: 0.8vw;
  position: absolute;
  left: 23.5%;
  top: 67.5%;
}
.explorer_level > h1 {
  position: relative;
  left: -50%;
  width: 100%;
}
.stored_item {
  position: absolute;
  width: 15%;
  left: 94%;
  top: 33%;
}
.stored_item > img {
  width: 100%;
}
.explorer_badge h4 {
  position: absolute;
  font-size: 3vw;
  left: 8%;
  top: 33%;
}
.explorer_badge hr {
  position: absolute;
  width: 40%;
  left: 35%;
  top: 42%;
  z-index: 2;
  border: 0.5px solid #ffffff74;
  border-radius: 2px;
}

.trophy_explorer {
  width: 27%;
  height: 20%;
  position: absolute;
  top: 9.5%;
  left: 7.2%;
  z-index: 2;
}
.trophy_explorer * {
  font-size: 1vw;
  font-family: Montserrat;
  font-style: normal;
}
.trophy_explorer h1,
.trophy_explorer h2,
.trophy_explorer h3 {
  position: absolute;
  width: 55%;
  left: 36%;
}
.trophy_explorer h4 {
  position: absolute;
  width: 55%;
  position: absolute;
  font-size: 3vw;
  left: 8%;
  top: 33%;
}
.trophy_explorer h3 {
  position: absolute;
  font-weight: normal;
  top: 47%;
}
.trophy_explorer h3 label {
  font-weight: bold;
}
.trophy_explorer img {
  width: 100%;
}
.trophy_explorer > .explorer_thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 20.5%;
}
.trophy_explorer > h1 {
  position: absolute;
  font-weight: bold;
  font-size: 1.5vw;
  top: 12%;
}
.trophy_explorer h2 {
  position: absolute;
  font-weight: 300;
  top: 30%;
}
.trophy_explorer h3 {
  position: absolute;
  font-weight: normal;
  top: 47%;
  label {
    font-weight: bold;
  }
}
tr .trophy_explorer h4 {
  position: absolute;
  font-size: 3vw;
  left: 8%;
  top: 33%;
}
.trophy_explorer hr {
  position: absolute;
  width: 40%;
  left: 35%;
  top: 42%;
  z-index: 2;
  border: 0.5px solid #ffffff74;
  border-radius: 2px;
}

.tablet_background > div {
  pointer-events: none;
}
.large_tablet_frame img {
  pointer-events: none;
}

.ph_testing .zoomable_image {
  filter: blur(0.8vw) brightness(0.6);
}

.large_pcr .can_drop_here {
  -webkit-filter: drop-shadow(0.3vw 0.3vw 2px #fff);
  filter: opacity(1) drop-shadow(0.3vw 0.3vw 2px #fff);
}

.lcd_screen {
  box-sizing: border-box;
  position: absolute;
  top: 27%;
  left: 36.5%;
  transform: scale(0.6);
  width: 4.4vw;
  height: 3.4vw;
  padding-top: 0.7vw;
  font-family: DSEG14-Classic-MINI;
  font-weight: bold;
  font-size: 1vw;
  line-height: 1vw;
  text-align: center;
  color: black;
}
.lcd_screen div:nth-child(2) {
  font-size: 0.6vw;
}
.lcd_screen small {
  font-size: 50% !important;
}

.sample_overlay img {
  position: absolute;
  left: 6.5%;
  width: 36%;
  bottom: 7%;
}

button.fullscreen {
  visibility: hidden;
}

.ant-modal {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  top: 100px;
  width: auto;
  margin: 0 auto;
  padding-bottom: 24px;
  pointer-events: none;
}
.ant-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}
.ant-modal-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}
.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 4px;
  -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  pointer-events: auto;
}
.ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}
.ant-modal-close:focus,
.ant-modal-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
}
.ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
}
.ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
}
.ant-modal-footer button + button {
  margin-bottom: 0;
  margin-left: 8px;
}
.ant-modal.zoom-enter,
.ant-modal.zoom-appear {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50);
}
.ant-modal-mask-hidden {
  display: none;
}
.ant-modal-open {
  overflow: hidden;
}
.ant-modal-centered {
  text-align: center;
}
.ant-modal-centered::before {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: "";
}
.ant-modal-centered .ant-modal {
  top: 0;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .ant-modal {
    max-width: calc(100vw - 16px);
    margin: 8px auto;
  }
  .ant-modal-centered .ant-modal {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}
.ant-modal-confirm .ant-modal-header {
  display: none;
}
.ant-modal-confirm .ant-modal-close {
  display: none;
}
.ant-modal-confirm .ant-modal-body {
  padding: 32px 32px 24px;
}
.ant-modal-confirm-body-wrapper {
  zoom: 1;
}
.ant-modal-confirm-body-wrapper::before,
.ant-modal-confirm-body-wrapper::after {
  display: table;
  content: "";
}
.ant-modal-confirm-body-wrapper::after {
  clear: both;
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}
.ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}
.ant-modal-confirm-body > .anticon {
  float: left;
  margin-right: 16px;
  font-size: 22px;
}
.ant-modal-confirm-body
  > .anticon
  + .ant-modal-confirm-title
  + .ant-modal-confirm-content {
  margin-left: 38px;
}
.ant-modal-confirm .ant-modal-confirm-btns {
  float: right;
  margin-top: 24px;
}
.ant-modal-confirm .ant-modal-confirm-btns button + button {
  margin-bottom: 0;
  margin-left: 8px;
}
.ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
  color: #f5222d;
}
.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #faad14;
}
.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  color: #1890ff;
}
.ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
  color: #52c41a;
}
