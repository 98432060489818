body {
  margin: 0;
  background-color: #132962;
  color: #fff;
  max-width: 1973px; /* 1920 × 1080 */
  margin: 0 auto;
  padding: 0px; /* this is the padding around the map */
}

body,
h1 {
  font-family: Montserrat;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
